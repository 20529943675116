<template>
  <div class="main__wrapper"  v-loading="loading">
    <!-- 文件异常提示 -->
    <el-dialog
      title="文件异常报告"
      :visible.sync="dialogTableVisible"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      :center="true"
      :destroy-on-close="true"
    >
      <div class="tips el-icon-warning-outline">
        以下歌曲文件存在异常情况，将不参与打分。
      </div>
      <el-table max-height="550px" stripe :data="abnormalSongList">
        <el-table-column
          show-overflow-tooltip
          prop="name"
          label="文件名"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="lyric_msg"
          label="异常原因"
        ></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <div id="Btn" @click="dialogTableVisible = false">确定</div>
      </span>
    </el-dialog>

    <!-- 表单 -->
    <div class="main">
      <div class="main-top-text">{{$t('h.sideBar.addTask')}}</div>
      
      <el-form
        label-width="auto"
        label-position="left"
        :model="formLabelAlign"
        class="main-form"
      >
        <el-form-item :label=" $t('h.task.uploadTaskName') + ':'">
          <el-input
            class="name-input"
            v-model="formLabelAlign.name"
            :placeholder=" $t('h.task.enterPlaceholder')"
          ></el-input>
        </el-form-item>

        <el-form-item v-if="$store.state.user.country !== 'China'" :label="$t('h.task.serviceModule') + ':'">
          <el-checkbox-group v-model="serviceModuleList">
            <el-checkbox label="playlist" key="playlist">Playlist Analysis</el-checkbox>
            <el-checkbox label="audience_profile" key="audience_profile">Audience Profile Prediction</el-checkbox>
            <el-checkbox label="short_video" key="short_video">Short-video Promotion Analysis</el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item v-if="$store.state.user.usertype ==='afrotunes'" :label="$t('h.task.selectGenre') + ':'">
          <el-radio-group v-model="genreType">
            <el-radio label="gospel">{{ 'Gospel & Christian' }}</el-radio>
            <el-radio label="others">{{$t('h.task.others')}}</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item :label =" $t('h.task.taskProperties') + ':'" v-if="$store.state.user.country == 'China' && $store.state.user.usertype !='afrotunes'">
          <el-radio-group v-model="formLabelAlign.scene_type">
            <el-radio-button label="purchase" >
              <p class="sence-button-title" > DEMO筛选</p>
              <p class="sence-button-quota">{{$t('h.task.songQuota')}}： {{purchaseNumber}}</p>
            </el-radio-button>
            <el-radio-button style="margin-left:30px" label="evaluation">
              <p class="sence-button-title" > 成品评估
                <el-tooltip class="item" effect="dark" placement="bottom">
                  <div slot="content">该功能开放试用中。如需体验请联系商务</div>
                  <i class="icon-attention1"></i>
                </el-tooltip>
              </p>
            <p class="sence-button-quota" >{{$t('h.task.songQuota')}}： {{evaluationNumber}}</p>
            </el-radio-button>
          </el-radio-group>
        </el-form-item>

        <!-- 模型选择 测试用 -->
        <!-- <el-form-item label="模型:">
          <el-select v-model="formLabelAlign.model" placeholder="请选择模型">
            <el-option label="v2" :value="'v2'"></el-option>
            <el-option label="v5" :value="'v5'"></el-option>
            <el-option v-if="formLabelAlign.scene_type === 'purchase'" label="demo_pdm_v1" :value="'demo_pdm_v1'"></el-option>
            <el-option v-if="formLabelAlign.sc  ene_type === 'evaluation'" label="v5_1" :value="'v5_1'"></el-option>
          </el-select>
        </el-form-item> -->

        <el-form-item :label ="$t('h.task.uploadFiles') +':'">
          <!-- 多文件上传 -->
          <el-upload
            multiple
            ref="upload"
            action=""
            :show-file-list="false"
            :file-list="fileList"
            :auto-upload="false"
            :on-change="selectMutilFile"
          >
            <el-button
              slot="trigger"
              size="small"
              :disabled="isFileUpload || isProgress"
            > 
              {{$t('h.task.selectFiles')}}
            </el-button>
          </el-upload>

          <div class="flex-box">
            <p class="upload-tips" v-html="$t('h.task.mutilFilesMsg')" />                
            <el-button
              v-if="!isFileUpload && trackFileList && trackFileList.length > 0"
              size="small"
              type="primary"
              :disabled="isProgress || !trackFileList.length"
              @click="uploadCos"
            >
              {{$t('h.task.uploadToServer')}}
            </el-button>
            <el-button
              v-if="isFileUpload && trackFileList && trackFileList.length > 0"
              size="small"
              type="primary"
              @click="uploadAgain"
            >
              {{$t('h.task.uploadAgain')}}
            </el-button>
          </div>

          <div
            id="file-list"
            class="mutil-file"
            :class="isFileUpload || isProgress ? 'is-fileupload' : ''"
          >
            <el-table
              ref="table"
              border
              :data="trackFileList"
              :header-cell-style="{'text-align':'center'}" 
              :cell-style="{'text-align':'center'}" 
              class="management-table"
            >
              <my-empty slot="empty" :empty-text="$t('h.mark.filemsg')"></my-empty>
              <el-table-column
                :label="$t('h.mark.no')"
                type="index"
                width="200px"
              >
              </el-table-column>
              <el-table-column
                property="name"
                :label="$t('h.mark.audio')"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                property="lyric.name"
                :label="$t('h.mark.lyric')"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                v-if="$store.state.user.country !== 'China'"
                property=""
                label="UPC"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-input v-model="scope.row['upc']" placeholder=""></el-input>
                </template>
              </el-table-column>
              <el-table-column
                v-if="$store.state.user.usertype == 'afrotunes'"
                property=""
                :label="$t('h.mark.artist')"
                show-overflow-tooltip
              >
              <template slot-scope="scope">
                <el-input v-model="scope.row['artist_name']" placeholder=""></el-input>
              </template>
              </el-table-column>
              <el-table-column
                property=""
                :label="$t('h.mark.artistC')"
                show-overflow-tooltip
                v-if="$store.state.user.usertype == 'afrotunes'"
              >
                <template slot-scope="scope">
                  <el-select clearable  filterable v-model="scope.row['artist_country']" placeholder="">
                    <el-option
                      v-for="item in country_list"
                      :key="item.abbreviation"
                      :label="item.vantage_name"
                      :value="item.abbreviation">
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('h.mark.operations')"
              >
                <template class="file-item" slot-scope="scope">
                  <el-tooltip effect="dark" :content="(scope.row.lyric?'replace lyric':'upload lyric')" placement="top">
                    <el-upload
                      action=""
                      :file-list="lyricFiles"
                      :show-file-list="false"
                      :auto-upload="false"
                      style="display:inline"
                      :on-change="(file, fileList) => {replaceLyric(file, fileList, scope.row)}"
                    >
                      <i
                      class="el-icon-sort "
                    ></i>
                    </el-upload>
                  </el-tooltip>
                <el-tooltip effect="dark" content="delete track" placement="top">
                  <i
                    class="el-icon-close ml15"
                    @click="deleteTrack(scope.row)"
                  ></i>
                </el-tooltip>
                
                </template>
              </el-table-column>
            </el-table>

            <div v-if="isFileUpload || isProgress" class="mask-box">
              <img
                v-show="!isProgress"
                class="upload-icon"
                src="~assets/dafen_shangchuan_icon@2x.png"
                alt=""
              />
              <el-progress
                v-show="isProgress"
                :text-inside="true"
                :stroke-width="20"
                :percentage="progressNum"
                class="animated fadeIn progress-com"
              ></el-progress>
              <p>{{ uploadText }}</p>
            </div>
          </div>
        </el-form-item>
      </el-form>
      
      <div
          @click="taskUpload"
          class="uploadbtn"
          :class="{ uploadshow: uploadReady }"
        >
          {{$t('h.task.submit')}}
      </div>
    </div>
  </div>
</template>

<script>
import COS from "cos-js-sdk-v5";
import country_list from './africa_list.json'
export default {
  data() {
    return {
      formLabelAlign: {
        name: "",
        scene_type: "purchase",
        topN: "",
        model: "demo_pdm_v1",
      },
      uploadType: "mutil",
      isFileUpload: false,
      dialogTableVisible: false,
      isProgress: false,
      progressNum: 0,
      fileReady: false,
      uploadText: this.$t('h.task.uploadingFile'),
      fileList: [],
      lyricFiles:[],
      task_id: "",
      song_info_list: [],
      zipFileName: "",
      zipFile: [],
      cosInfo: null,
      loading: false,
      artistsNameList:[],
      abnormalSongList: [],
      serviceModuleList:[],
      // 记录已选择的音频文件对象
      trackFileList: [],
      // 记录已选择的歌词文件对象
      lyricFileList: [],
      // 记录已选择的音频文件
      audioList: [],
      // 记录已选择的歌词文件
      lyricList: [],
      cosBucket:'',
      cosRegion:'',
      purchaseNumber:this.$t('h.task.unlimited'),
      evaluationNumber:this.$t('h.task.unlimited'),
      genreType: 'others'
    };
  },

  computed: {
    token: function () {
      return localStorage.getItem("token");
    },
    country_list:function () {
      return country_list;
    },
    uploadReady: function () {
      if (
        this.fileReady &&
        this.formLabelAlign["scene_type"] &&
        (this.serviceModuleList[0] || this.$store.state.user.country == 'China')
      ) {
        return true;
      } else {
        return false;
      }
    },
  },

  watch: {
    "formLabelAlign.scene_type"(val) {
      if (val === "purchase") {
        this.formLabelAlign.model = "demo_pdm_v1"
      } else {
        this.formLabelAlign.model = "v5_1"
      }
    },
  },

  mounted() {
    this.checkToken()
    this.getQuota()
  },
  methods: {
    // 初始化表单
    initForm() {
      this.formLabelAlign = {
        name: "",
        scene_type: "",
        topN: "",
        model: "demo_pdm_v1",
      };
      this.formLabelAlign.scene_type = 'purchase'
      this.song_info_list = [];
      this.fileReady = false;
      this.fileList = [];
      this.trackFileList = [];
      this.lyricFileList = [];
      this.audioList = [];
      this.lyricList = [];
      this.zipFile = [];
      this.isFileUpload = false;
      this.isProgress = false;
      this.progressNum = 0;
    },

    checkToken() {
      let token = localStorage.getItem("token");
      if (!token) {
        this.$router.push("/");
      }
      if (this.$store.state.user.country == 'China'){
        this.cosBucket='tme-chuangxin-1258344705'
        this.cosRegion='ap-guangzhou'
      }else{
        this.cosBucket='tme-chuangxin-hw-1258344705'
        this.cosRegion='ap-hongkong'
      }
    },

    deleteTrack(row){
      const dotIndex = row.name.lastIndexOf('.')
      const name = row.name.substring(0, dotIndex)
      const index = this.audioList.indexOf(name)
      this.trackFileList.splice(index, 1)
      this.audioList.splice(index, 1)
      if (row.lyric) {
        this.deleteLyric(row)
      }
    },

    deleteLyric(row){
      const dotIndex = row.lyric.name.lastIndexOf('.')
      const name = row.lyric.name.substring(0, dotIndex)
      const index = this.lyricList.indexOf(name)
      this.lyricList.splice(index, 1)
      this.lyricFileList.splice(index, 1)
      row.lyric.name = ''
    },

    // 选择多文件
    selectMutilFile(file) {
      const reg = /\.(mp3|wav|txt|docx|doc)$/;
      const isAudio = /\.(mp3|wav)$/.test(file.name)
      const isLyric = /\.(txt|docx|doc)$/.test(file.name)
      if (reg.test(file.name)) {
        const dotIndex = file.name.lastIndexOf('.')
        const name = file.name.substring(0, dotIndex)
        const fileName = name
        if (isAudio) {
          // 是音频文件
          if (this.audioList.indexOf(fileName) !== -1) {
            // 已存在同名文件
            setTimeout( () => {
              this.$message({
                message: `${name}` + this.$t('h.task.reselectMsg'),
                type: "warning",
                offset: 120,
                showClose: true,
                duration: 2000
              })
            },100)
            return
          }
          // 存在同名歌词文件
          else if(this.lyricList.indexOf(fileName) !== -1){
            let lyric = this.lyricFileList[[this.lyricList.indexOf(fileName)]]
            file['lyric'] = lyric
            this.audioList.push(fileName)
            this.trackFileList.push(file)
          } 
          else {
            // 不存在同名文件
            this.audioList.push(fileName)
            this.trackFileList.push(file)
          }
        }
        // 是歌词文件且已存在同名文件
        if (isLyric) {
          // 是歌词文件
          if (this.lyricList.indexOf(fileName) !== -1) {
            // 已存在同名文件
            setTimeout( () => {
                this.$message({
                message: `【${name}】` + this.$t('h.task.reselectMsg'),
                type: "warning",
                offset: 120,
                showClose: true,
                duration: 2000
              })
            },100)
            return
          } 
          else if(this.audioList.indexOf(fileName) !== -1){
            // 存在同名歌曲文件
            setTimeout( () => {
              this.$set(this.trackFileList[this.audioList.indexOf(fileName)],'lyric' , file)
              this.lyricList.push(fileName)
              this.lyricFileList.push(file)
            },500)
          }
          else{
            // 不存在同名歌曲文件
            this.lyricList.push(fileName)
            this.lyricFileList.push(file)
            return
          }
        }
        // console.log(this.trackFileList)
        this.fileList.push(file);
      } else {
        setTimeout( () => {
         this.$message({
          message: (this.$i18n.locale === 'en' ? '' : file.name) + this.$t('h.task.formatMsg'),
          type: "error",
          offset: 120,
          showClose: true,
          duration: 6000,
        })},100)
      }
    },
    // 替换歌词文件
    replaceLyric(file,list,row){
      const isLyric = /\.(txt|docx|doc)$/.test(file.name)
      const suffix = file.name.substring(file.name.lastIndexOf('.'))
      const dotIndex = row.name.lastIndexOf('.')
      const name = row.name.substring(0, dotIndex)
      file.name = name + suffix
      if (isLyric) {
        row['lyric'] = file
        this.lyricFiles =[]
        this.$message({
          message: this.$i18n.locale === 'en' ? 'Replace Success' : '替换成功',
          type: "success",
          offset: 120,
          showClose: true,
          duration: 6000,
        })
      }
      else {
        setTimeout( () => {
          this.$message({
            message: (this.$i18n.locale === 'en' ? '' : file.name) + this.$t('h.task.formatMsg'),
            type: "error",
            offset: 120,
            showClose: true,
            duration: 6000,
          })
        },100)
      }
    },
    // 提交打分
    async taskUpload() {
      console.log(this.serviceModuleList)
      if (
        this.fileReady &&
        this.formLabelAlign["scene_type"] && 
        (this.serviceModuleList[0] || this.$store.state.user.country == 'China')
      ) {
        if(this.$store.state.user.country != 'China' || this.$store.state.user.usertype  == 'afrotunes'){
          this.formLabelAlign["scene_type"] = 'evaluation'
        }
        if(this.$store.state.user.usertype  == 'afrotunes'){
          this.song_info_list.forEach(item => {
            const index = this.audioList.indexOf(item.name)
            if (index !== -1) {
              item.artist_name = this.trackFileList[index].artist_name || ''
              item.artist_country = this.trackFileList[index].artist_country || ''
              item.upc = this.trackFileList[index].upc || ''
            }
          })
        }
        console.log(this.song_info_list)
        this.loading = true
        const result = await this.$store.dispatch("createdData", {
          token: this.token,
          task_id: this.task_id,
          topn: "",
          scene_type: this.formLabelAlign["scene_type"],
          task_name: this.formLabelAlign["name"],
          song_info_list: this.song_info_list,
          model: this.formLabelAlign["model"],
          playlist:this.serviceModuleList.indexOf('playlist')=== -1 ?0 : 1,
          audience_profile:this.serviceModuleList.indexOf('audience_profile') === -1 ?0 : 1,
          short_video:this.serviceModuleList.indexOf('short_video')=== -1 ?0 : 1,
          tags: {
            is_gospel: this.genreType === 'gospel'
          }
        });
        if (result.code == 2000) {
          this.$message({
            message:
              this.$t('h.task.afterSubmitMsg'),
            type: "success",
            offset: 120,
          });
          this.initForm();
        } else {
          this.$message({
            message: result.msg,
            type: "error",
            offset: 120,
          });
        }
         this.loading = false
      } else {
        this.$message({
          message: this.$t('h.task.completeFormMsg'),
          type: "warning",
          offset: 120,
        });
      }
    },

    // 上传至COS
    async uploadCos() {
      if (this.uploadType === "mutil") {
        // 音频、歌词文件数量不对齐时，提示用户重新检查
        if (this.audioList.length > this.lyricList.length && this.$store.state.user.country == 'China' && this.$store.state.user.usertype  != 'afrotunes') {
          this.$message({
            message: "部分音频文件缺少对应歌词文件,请检查后上传",
            type: "error",
            offset: 120
          })
          return
        }
        const filelist = document.getElementById("file-list");
        if (filelist) {
          filelist.scrollTop = 0;
        }
      }
      // for(let i =0 ; i < this.trackFileList.length ;i++ ){
      //   if (!this.trackFileList[i]['artist_country'] && this.$store.state.user.usertype  == 'afrotunes'){
      //       this.$message({
      //         message: this.$t('h.mark.countryMsg'),
      //         type: "error",
      //         offset: 120,
      //       });
      //       this.isProgress = false;
      //       this.progressNum = 0;
      //       return
      //     }
      // }
      this.isProgress = true;
      this.progressNum = 0;
      const d = await this.$store.dispatch("getCosInfo", {
        token: localStorage.getItem("token"),
      });
      if (d.success) {
        this.cosInfo = d.result;
        this.task_id = this.cosInfo.taskid;
        const cosInfo = this.cosInfo;
        // COS实例
        const cos = new COS({
          SecretId: cosInfo && cosInfo.cos_secret_id,
          SecretKey: cosInfo && cosInfo.cos_secret_key,
          SecurityToken: cosInfo.cos_token,
        });
        // 汇总文件
        const fileList = [];
        this.trackFileList.forEach((file) => {
          fileList.push({
            Bucket:
              this.cosBucket /* 填入您自己的存储桶，必须字段 */,
            Region:
              this.cosRegion /* 存储桶所在地域，例如ap-beijing，必须字段 */,
            Key: `${cosInfo.key}${file.name}` /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */,
            Body: file.raw,
            onTaskReady: () => {
              /* taskId可通过队列操作来取消上传cos.cancelTask(taskId)、停止上传cos.pauseTask(taskId)、重新开始上传cos.restartTask(taskId) */
              // console.log("taskId");
            },
          });
          if (file.lyric) {
            fileList.push({
              Bucket:
                this.cosBucket /* 填入您自己的存储桶，必须字段 */,
              Region:
                this.cosRegion /* 存储桶所在地域，例如ap-beijing，必须字段 */,
              Key: `${cosInfo.key}${file.lyric.name}` /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */,
              Body: file.lyric.raw,
              onTaskReady: () => {
                /* taskId可通过队列操作来取消上传cos.cancelTask(taskId)、停止上传cos.pauseTask(taskId)、重新开始上传cos.restartTask(taskId) */
                // console.log("taskId");
              },
            });
          }
        });
        console.log(fileList)
        // 批量上传
        const self = this;
        cos.uploadFiles(
          {
            files: fileList,
            SliceSize: 1024 * 1024 * 10 /* 设置大于10MB采用分块上传 */,
            onProgress: (info) => {
              let percent = parseInt(info.percent * 10000) / 100;
              // let speed = parseInt(info.speed / 1024 / 1024 * 100) / 100
              self.progressNum = percent;
              self.uploadText = self.$t('h.task.uploadingFile');
              // console.log('进度：' + percent + '%; 速度：' + speed + 'Mb/s;')
            },
            onFileFinish: (err, data, options) => {
              const arr = options.Key.split('/')
              const fileName = arr[arr.length - 1]
              if (err) {
                setTimeout(() => {
                  self.$message({
                    message: fileName + self.$t('h.task.uploadFailMsg'),
                    type: "error",
                    offset: 120,
                  });
                }, 100)
              } else {
                console.log(fileName + ' ' + self.$t('h.task.uploadSuccess'));
              }
            },
          },
          function (err) {
            // console.log(err || data)
            if (!err) {
              self.uploadText = self.$t('h.task.checkingMsg');
              // 上传成功获取文件列表
              self.getFileList();
            } else {
              self.$message({
                message: self.$t('h.task.uploadFailMsg'),
                type: "error",
                offset: 120,
              });
              self.cancelUpload();
            }
          }
        );
      } else {
        this.$message({
          message: d.msg,
          type: "error"
        })
      }
    },

    // 上传成功后查询文件名是否有问题
    async getFileList() {
      const d = await this.$store.dispatch("getUploadCosFiles", {
        token: localStorage.getItem("token"),
        taskid: this.task_id,
      });
      if (d.success) {
        if (d.file_info.error_code && this.$store.state.user.country == 'China'&& this.$store.state.user.usertype  != 'afrotunes') {
          this.dialogTableVisible = true;
        }
        if (this.uploadType === "zip") {
          this.uploadText = this.zipFileName;
        } else {
          this.uploadText =  this.$t('h.task.checkedMsg');
        }
        this.isProgress = false;
        this.progressNum = 0;
        this.song_info_list = d.file_info.song_info_list;

        if(this.$store.state.user.country == 'China'&& this.$store.state.user.usertype  != 'afrotunes'){
          this.song_info_list.forEach(item => {
            if (item.lyric_msg) {
              item.delete = true
            }
          })
        }
        this.abnormalSongList = this.song_info_list.filter(item => !!item.lyric_msg)
        this.fileReady = true;
        this.isFileUpload = true;
      } else if (d.code === 4008) {
        // 上传的文件为空
        this.resetFileUpload()
      } else {
        // 文件校验失败，删除已上传文件
        this.cancelUpload()
        this.$message({
          message: this.$t('h.task.checkedFailMsg'),
          type: "error", 
          offset: 120,
        })
      }
    },

    // 多文件重新上传
    uploadAgain() {
      this.$confirm(this.$t('h.task.clearSongsMsg'), this.$t('h.task.prompt'), {
        showConfirmBtn: true,
      }).then(() => {
        this.cancelUpload();
      });
    },

    // 取消文件上传
    cancelUpload() {
      this.dialogTableVisible = false;
      const cosInfo = this.cosInfo;
      const self = this;
      // COS实例
      const cos = new COS({
        SecretId: cosInfo && cosInfo.cos_secret_id,
        SecretKey: cosInfo && cosInfo.cos_secret_key,
        SecurityToken: cosInfo.cos_token,
      });
      const deleteFiles = function (marker) {
        cos.getBucket(
        {
          Bucket:
            self.cosBucket /* 填入您自己的存储桶，必须字段 */,
          Region:
            self.cosRegion /* 存储桶所在地域，例如ap-beijing，必须字段 */,
          Prefix: `${cosInfo.key}`,
          Marker: marker,
          MaxKeys: 1000,
        },
        function (listError, listResult) {
          if (listError) return console.log("list error:", listError);
          let nextMarker = listResult.NextMarker;
          let objects = listResult.Contents.map(function (item) {
            return { Key: item.Key };
          });
          cos.deleteMultipleObject(
            {
              Bucket: self.cosBucket,
              Region: self.cosRegion,
              Objects: objects,
            },
            function (delError, deleteResult) {
              if (delError) {
                console.log("delete error", delError);
                console.log("delete stop");
              } else {
                console.log("delete result", deleteResult);
                if (listResult.IsTruncated === "true") {
                  deleteFiles(nextMarker);
                } else {
                  console.log("delete complete");
                  self.resetFileUpload()
                }
              }
            }
          );
        }
        );
      };
      deleteFiles();
    },
    async getQuota(){
      const d = await this.$store.dispatch("getQuota", {
        token: localStorage.getItem("token"),
      });
      if (d.success) {
        const pNumber = d.data.purchase_number
        const eNumber = d.data.evaluation_number
        if (pNumber == -2){
          this.purchaseNumber = this.$t('h.task.unlimited')
        }else if(pNumber == -1){
          this.purchaseNumber = this.$t('h.task.withoutPermission')
        }else{
          this.purchaseNumber =pNumber + '首'
        }
        if (eNumber ==-2){
          this.evaluationNumber = this.$t('h.task.unlimited')
        }else if(eNumber == -1){
          this.evaluationNumber = this.$t('h.task.withoutPermission')
        }else{
          this.evaluationNumber =eNumber + '首'
        }
      } else {
        this.$message({
          message: d.msg,
          type: "error"
        })
      }
},
    // 重置为未上传文件状态
    resetFileUpload () {
      this.song_info_list = []
      this.fileReady = false
      this.fileList = []
      this.trackFileList = []
      this.lyricFileList = []
      this.audioList = []
      this.lyricList = []
      this.zipFile = []
      this.isFileUpload = false
      this.progressNum = 0
      this.isProgress = false
    }
  },
};
</script>

<style lang="stylus" scoped>
.main__wrapper{
  height: 100%;

  .main{
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
.main-top-text {
  margin: 10px 0 0 0;
  font-size: 18px;
  font-weight: 600;
  color: #1a1a1a;
}

.dialog-footer {
  display: flex;
  justify-content: center;
}

#cancelBtn, #Btn {
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  width: 120px;
  height: 30px;
  border-radius: 30px;
}

#Btn {
  margin-left: 20px;
  background: #ff8c36;
  color: #fff;
}

#cancelBtn {
  margin-right: 20px;
  color: #ff8c36;
  border: 1px solid #ff8c36;
}

.tips {
  font-size: 14px;
  color: #ff8c36;
  line-height: 20px;
}

.err {
  color: red;
}

.uploadbtn {
  width: 160px;
  height: 40px;
  margin: 0 auto;
  color: #fff;
  line-height: 40px;
  text-align: center;
  background: #ffe8d7;
  border-radius: 30px;
  cursor: not-allowed;
}

.uploadshow {
  background: #ff8c36;
  cursor: pointer;
}

.main-form{
  flex: 1;
  width: 100%;
  margin-top:20px;
}

.check-btn {
  cursor: pointer;
  color: #ff8c36;
}
.sence-button-title{
  line-height:24px;
  font-size: 14px;
  font-weight:600;
}
.sence-button-quota{
  min-width: 120px;
  line-height: 20px;
  font-size: 10px
}
.upload-dragger {
  width: 100%;
  height: 200px;
  border-radius: 6px;
  /* opacity: 0.2; */
  border: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.upload__text {
  font-size: 12px;
  text-align: center;
  font-weight: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: 0px;
  color: #8b8888;
}

.scale-progress {
  width: 360px;
  height: 20px;
  margin-top: 14px;
  transform: scale(0.75);
}

.close-icon {
  margin: 5px auto 0;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  color: #fff;
  border-radius: 16px;
  background: #d6d6da;
  cursor: pointer;
}

.mutil-file {
  font-size: 12px;
  color: #606266;
  overflow: auto;
  position: relative;
  
  i {
    cursor: pointer;
  }

  .file-item {
    display: flex;
    justify-content: space-between;
    // line-height: 22px;
    margin-bottom: 4px;
    padding: 0 5px;
    white-space: pre;

    .remove-btn {
      display: none;
    }
  }

  .file-item:hover {
    background: #f5f7fa;

    .upload-success {
      display: none;
    }

    .remove-btn {
      display: inline;
    }
  }

  .mask-box {
    position: absolute;
    z-index: 100;
    height: 100%;
    width: 100%;
    top: 50px;
    left: 0;
    background: rgba(255, 255, 255, 0.5);
    text-align: center;
    padding-top: 30px;
    line-height: 24px;

    .progress-com {
      margin: 20px auto 10px;
      width: 70%;
      transform: scale(0.8);
    }
  }
}

.management-table {
  height: 260px;
  overflow: auto;
}

.is-fileupload {
  overflow: hidden;

  .management-table{
    opacity: 0.2; 
  }

  .file-item {
    opacity: 0.2;
  }
}
.my-empty{
  margin: 5% auto;
}
.flex-box {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.upload-tips {
  width: 85%;
  color: #999;
  font-size: 12px;
  line-height: 16px;
}
.name-input{
  width:50%;
}
::v-deep .el-form-item {
  margin-bottom: 10px;
}

::v-deep .el-upload {
  display: inline;
}

::v-deep .el-upload-dragger {
  width: 100%;
  height: 200px;
  border-radius: 6px;
  /* opacity: 0.2; */
  border: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

::v-deep .el-upload__text {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: 0px;
  color: #8b8888;
}

::v-deep .el-upload-dragger .el-upload__text em {
  color: #8b8888;
  text-decoration: underline;
}

::v-deep .upload-icon {
  width: 56px;
  height: 37px;
  /* margin-bottom: 40px; */
}

/* ::v-deep .el-form-item__label {
  font-size: 14px;
  color: #1a1a1a;
} */
::v-deep .el-input__inner {
  font-size: 12px;
  height:35 px;
}

::v-deep .el-progress-bar__inner {
  background: linear-gradient(270deg, #fae62b 0%, #ff8c36 100%);
}

::v-deep .el-dialog {
  border-radius: 8px;
}

::v-deep .el-dialog__header {
  background: #f5f6fa;
  border-radius: 8px 8px 0 0;
}
::v-deep .el-radio-button .el-radio-button__inner{
  border-radius: 5px;
  border: 1px solid #dcdfe6;
  padding: 10px; 
}
::v-deep .el-radio-button__orig-radio:checked+.el-radio-button__inner{
  background-color:#fff;
  color:#ff8c36;
  border: 1px solid #ff8c36
  box-shadow:0 0 #fff
}
::v-deep .el-table td,
::v-deep .el-table th.is-leaf,
::v-deep .el-table--border {
  border-bottom: 0.076923rem solid #ebeef5;
  font-size:12px;
}
::v-deep .el-form-item__content {
  line-height: 33px;
}
::v-deep .el-table tr {
  line-height: 22px;
}
::v-deep .el-form-item__label {
  line-height: 33px;
}
::v-deep .el-form-item {
  margin-bottom: 14px;
}
</style>